.main-manage-div{
    background-color: rgb(35, 35, 35);
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 150px;
    border-radius: 5px;
    min-height: 480px;
}

.preview-main-div{
    display: flex;
    margin-bottom: 200px;
    margin-top: 20px;
    margin-left: 100px;
    margin-right: 100px;
}

@media (max-width: 900px) {
    .main-manage-div{
        margin-left: 10px;
        margin-right: 10px;
    }

    .preview-main-div{
        margin-left: 10px;
        margin-right: 10px;
    }
}

.manage-button{
    border: none;
    height: 40px;
    width: 120px;
    background-color: rgb(56, 56, 56);
    color: white;
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 0px 0px 5px 5px;
    margin-left: 5px;

    &:hover{
        background-color: rgb(70, 70, 70);
    }
}

.manage-pages-button{
    border: none;
    height: 40px;
    width: 120px;
    background-color: rgb(35, 35, 35);
    color: white;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 15px;
    margin-left: 5px;
    margin-bottom: -5px;
    opacity: 0.5;

    &:hover{
        background-color: rgb(45, 45, 45);
        opacity: 1;
    }
}

.manage-input [contenteditable="true"] {
    outline: none !important;
    box-shadow: none !important;
    background-color: rgb(45, 45, 45);
    min-height: 305px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px 5px 5px 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0.1px;
    padding-bottom: 30px;
    margin-top: 10px;
}

.manage-change-button{
    border: none;
    height: 50px;
    width: 120px;
    background-color: rgb(45, 45, 45);
    color: white;
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 0px 0px 5px 5px;
    margin-left: 5px;
    border-top: 1px solid #505050;
    
    &:hover{
        background-color: rgb(56, 56, 56);
    }
}


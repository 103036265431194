.cart-product-div {
    background-color: rgb(50, 50, 50);
    border-radius: 5px;
    height: 120px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    overflow-x: hidden;
}

.cart-delete-text {
    transition: all 0.15s ease;
    margin-left: auto;
    margin-right: 10px;
    color: red;
    opacity: 0.7;
    cursor: pointer;
}

.cart-delete-text:hover {
    opacity: 1;
}

/* Адаптація для вузьких екранів */
@media (max-width: 530px) {
    .modal-content {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0;
        margin-right: 0;
        height: auto;
    }

    .cart-product-div {
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: auto;
        padding: 10px;
    }

    .cart-product-div img {
        width: 80px;
        height: 80px;
    }

    .cart-delete-text {
        margin: 5px 0;
    }
}

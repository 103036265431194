.main-modal-link-button {
    color: white;
    text-decoration: none;
    border: solid 1px white;
    border-radius: 50px;
    padding: 20px;

    &:hover{
        background-color: white;
        color: black;
    }
}

.main-modal-link-button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
  
.modal-content {
    background-color: rgb(87, 87, 87);
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    width: 500px; /* Фиксированная ширина для больших экранов */
    height: 500px; /* Фиксированная высота для больших экранов */
}

@media (max-width: 530px) {
    .modal-content{
        transform: scale(0.8);
    }
}

@media (max-width: 460px) {
    .modal-content {
        transform: scale(0.8);
        width: 100%; /* Ширина 100% для маленьких экранов */
        height: auto; /* Автоматическая высота */
        max-width: 100%; /* Максимальная ширина 100% */
        max-height: 90%; /* Максимальная высота 90% */
        padding: 10px; /* Уменьшаем отступы */
        transform: none; /* Убираем масштабирование */
        margin-left: 10px;
        margin-right: 10px;
    }

    .main-modal-link-button {
        padding: 10px; /* Уменьшаем отступы для кнопок */
        font-size: 14px; /* Уменьшаем размер шрифта */
    }

    h2 {
        font-size: 20px; /* Уменьшаем размер заголовка */
    }

    h3 {
        font-size: 16px; /* Уменьшаем размер подзаголовка */
    }
}
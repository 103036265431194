.view-images-for-admin-img-div{
    border: solid 1px white;
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
}

.view-images-for-admin-delete-button{
    height: 40px;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    color: red;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: rgb(231, 231, 231);

    &:hover{
        background-color: white;
    }
}
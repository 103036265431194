.cart-icon-main-div {
    color: white;
    text-decoration: none;
    font-size: 20px;
    transition: font-size 0.3s ease;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 50px;

    &:hover {
        font-size: 25px;
        color: rgb(223, 223, 0);
      }
}

@media (max-width: 800px) {
    .cart-icon-main-div{
        font-size: 15px;
    }
}

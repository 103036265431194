.catalog-button-img {
    max-width: 150px;
    max-height: 150px;

}

.catalog-div {
    display: flex;
    background-color: #302929;
    height: 150px;
    align-items: center;
    justify-content: center;
    width: auto;
}

.catalog-button-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px;

    p {
        font-size: 25px;
    }
}

.catalog-retractable-main-div {
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
}

.catalog-button-main-div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(50, 50, 50);
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 300px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    flex-direction: column;

    &:hover {
        transform: scale(1.05);
        color: rgb(223, 223, 0);
        margin-top: 20px;
    }

    &:hover .catalog-retractable-main-div {
        opacity: 1;
        max-height: 500px;
    }
}

.catalog-main-div {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media (max-width: 1230px) {
    .catalog-main-div {
        flex-wrap: wrap;
        max-height: 300px;
        margin-bottom: 400px;
    }

    .catalog-main-center-div {
        display: flex;
        justify-content: center;
    }

    .catalog-button-main-div {
        margin: 10px;
        &:hover {
            margin-top: 0px;
            .catalog-retractable-main-div {
                opacity: 0;
                max-height: 0;
            }
        }
    }

}

@media (max-width: 640px) {
    .catalog-button-main-div{
        transform: scale(0.8);
        margin: -20px;
        margin-top: -20px;
        &:hover{
            margin-top: -20px;
            transform: scale(0.85);
        }
    }

    .catalog-main-div{
        margin-top: 10px;
    }

}

@media (max-width: 520px) {
    .catalog-button-main-div{
        transform: scale(0.6);
        margin: -50px;
        margin-top: -50px;
        &:hover{
            margin-top: -50px;
            transform: scale(0.65);
        }
    }

    .catalog-main-div{
        margin-top: 10px;
    }
}

@media (max-width: 399px) {
    .catalog-button-main-div{
        transform: scale(0.8);
        margin: -20px;
        margin-top: -20px;
        &:hover{
            margin-top: -20px;
            transform: scale(0.85);
        }
    }

    .catalog-main-div{
        margin-top: 10px;
    }

    .catalog-main-div {
        margin-bottom: 700px;
    }

}


.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-gallery-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-gallery img {
  margin: 10px;
  transition: transform 0.2s;
}

.image-gallery img:hover {
  transform: scale(1.3);
}

.dlt{
  opacity: 0.5;
  position: absolute;
  text-decoration: none;
}

.dlt:hover{
  opacity: 1;
}

.link-for-text{
  color: white;
  width: 200px;
  text-decoration: none;
  opacity: 0.7;
}

.link-for-text:hover{
  opacity: 1;
}

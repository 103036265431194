.pre-order-div{
    background-color: rgb(50, 50, 50);
    margin-left: 5px;
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.pre-order-buy-button{
    height: 40px;
    width: 150px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pre-order-buy-button:hover{
    background-color: rgb(252, 97, 22);
    color: white;
}

.pre-order-list-img-div{
    border-radius: 5px;
    border: 1px solid rgb(131, 131, 131);
    cursor: pointer;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2.5px;
    margin-right: 2.5px;

    -webkit-user-select: none;
    -moz-user-select: none;  
    -ms-user-select: none;   
    user-select: none; 
}

.pre-order-chevron-icon{
    height: 35px;
    width: 35px;
    color: rgb(131, 131, 131);
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 5px;
    margin-right: 5px;
}

.pre-order-chevron-icon:hover{
    transform: scale(1.03);
    color: rgb(189, 189, 189)
}

.pre-order-between-div{
    display: flex;
    justify-content: space-between;
}

.pre-order-image-list-div{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.pre-order-main-image{
    max-width: 350px;
    max-height: 350px;
}

.pre-order-image-div-width{
    width: 40%;
}

.pre-order-info-div-width{
    width: 100%;
}







@media (max-width: 1290px) {
    .pre-order-image-list-div{
        transform: scale(0.8);
    }
}

@media (max-width: 830px) {
    .pre-order-image-list-div{
        transform: scale(0.6);
    }
}

@media (max-width: 920px) {
    .pre-order-main-image{
        max-width: 250px;
        max-height: 250px;
    }
}

@media (max-width: 660px) {
    .pre-order-main-flex-div{
        display: flex;
        justify-content: center;
    }

    .pre-order-image-list-div{
        transform: scale(0.8);
    }

    .pre-order-between-div{
        display: block;
    }

    .pre-order-image-div-width{
        width: 100%;
    }
    
    .pre-order-info-div-width{
        width: 100%;
    }

}

@media (max-width: 420px) {
    .pre-order-main-flex-div {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pre-order-between-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .pre-order-image-div-width,
    .pre-order-info-div-width {
        width: 100%;
        max-width: 100%;
    }

    .pre-order-main-image {
        max-width: 100%;
        height: auto;
    }
}

.app-cart-div{
    position: absolute;
    right: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.app-cart-div:hover{
    transform: scale(1.2);
}

.app-cart-point{
    position: absolute;
    right: -3px;
    top: -39px;
    transform: scale(2.5);
    color: rgb(252, 97, 22);
    height: 0px;
}

.app-cart-number{
    position: absolute;
    top: -12px;
    font-size: 10px;
    color: white;
}

@media (max-width: 850px) {
    .app-cart-div{
        position: relative;
    }
}
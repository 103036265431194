.catalog-div-main {
    display: flex;
    justify-content: center;
    width: auto;
}

.catalogButt {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    text-decoration: none;
    font-size: 25px;
    border-radius: 50px;
    background-color: rgb(10, 10, 10);
    transition: all 0.3s ease;
    max-height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    width: 300px;
}

.catalogButt:hover {
    transform: scale(1.1);
    color: rgb(223, 223, 0);
}

.linking-image{
    max-width: 50px;
    max-height: 50px;
    margin-right: 10px;
}

@media (max-width: 1500px) {
    .catalogButt{
        width: auto;
        word-break: break-all;
        text-align: center;
    }
    .linking-image{
        display: none;
    }
}

@media (max-width: 800px) {
    .catalog-div{
        display: block;
        height: 500px;
        overflow: hidden;
    }

    .catalogButt{
        margin-top: 12px;
    }

    .linking-image{
        display: block;
    }
}
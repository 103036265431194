body {
  margin-left: 0px;
  margin-right: 0px;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(15, 15, 15);
  color: white;
  margin-top: 130px;
}

* {
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.hidden-space {
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  background-color: red;
  color: red;
}


.footer {
  /* Фиксированное положение */
  bottom: 0;
  /* Расположение внизу страницы */
  width: 100%;
  /* Ширина 100% */
  color: white;
  /* Цвет текста по вашему выбору */
  padding: 20px;
  /* Отступы по вашему выбору */
  box-sizing: border-box;
  /* Учитываем padding в расчетах ширины */
}

.homeButt {
  border: 2px solid white;
  text-align: center;
  padding: 20px;
  color: white;
  text-decoration: none;
  font-size: 25px;
  border-radius: 50px;
  width: 550px;
  transform: translate(-50%, -50%);
}

.homeButt:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(62, 62, 62);
}

.accessories {
  color: white;
  font-size: 20px;
  text-decoration: none;
  transition: font-size 0.3s ease;
}

.accessories:hover {
  font-size: 25px;
  color: rgb(223, 223, 0);
}

.static-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgb(10, 10, 10);
  box-shadow: 0 0 10px rgb(10, 10, 10);
}

.group {
  position: relative;
  margin-bottom: 30px;
}

.groupSupp {
  position: relative;
  margin-bottom: 30px;
}

.home {
  text-decoration: none;
  color: white;
}

.butt {
  color: white;
  text-decoration: none;
  font-size: 20px;
  transition: font-size 0.3s ease;
  height: 24px;
  /* Фиксированная высота элемента */
  display: inline-block;
  /* Добавлено для корректного отображения блока */
  vertical-align: middle;
  /* Выравнивание по вертикали */
  margin-right: 50px;
}

.butt:hover {
  font-size: 25px;
  color: rgb(223, 223, 0);
}

input {
  font-size: 16px;
  padding: 10px;
  display: block;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
}

input:focus {
  outline: none;
}

label {
  color: rgb(255, 255, 255);
  font-size: 18px;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus~label,
input:valid~label {
  top: -15px;
  font-size: 14px;
  color: #c2e500;
}


/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
}

.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  left: 0px;
  position: absolute;
  background: #c2e500;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}


/* active state */
input:focus~.bar:before,
input:focus~.bar:after {
  width: 110%;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #535353 #202020;
}

.floating-button {
  text-decoration: none;
  display: inline-block;
  width: 140px;
  height: 45px;
  line-height: 45px;
  border-radius: 45px;
  margin: 10px 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  font-weight: 600;
  color: #524f4e;
  background: rgb(255, 255, 255);
  box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
  transition: .3s;
  border: none;
  cursor: pointer;
}

.floating-button:hover {
  background: #2EE59D;
  box-shadow: 0 15px 20px rgba(46, 229, 157, .4);
  color: white;
  transform: translateY(-7px);
}

.check-psw {
  block-size: 25px;
  opacity: 0.8;
}

.check-psw:hover {
  opacity: 1;
}

.quest {
  margin-left: 10px;
  text-decoration: underline;
  color: white;
  opacity: 0.8;
}

.quest:hover {
  opacity: 1;
}

.div-for-catalog {
  width: 300px;
  height: 435px;
  background-color: rgb(106, 106, 106);
  border-radius: 15px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.div-for-catalog-two {
  width: 450px;
  height: 700px;
  background-color: rgb(106, 106, 106);
  border-radius: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  transform: scale(0.8);
}

.div-for-catalog-three {
  width: 350px;
  height: 500px;
  background-color: rgb(106, 106, 106);
  border-radius: 15px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.catalog-link {
  border: 2px solid rgb(255, 255, 255);
  text-align: center;
  padding: 10px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 25px;
  width: 300px;
  border-radius: 50px;
  font-weight: normal;
}

.catalog-link:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(62, 62, 62);
}

.div-phone {
  justify-content: center;
  justify-items: center;
  display: flex;
}

.butt2 {
  color: white;
  text-decoration: none;
  font-size: 20px;
  height: 28px;
  transition: font-size 0.3s ease;
  margin-left: 30px;
}

.butt2:hover {
  font-size: 25px;
  color: rgb(223, 223, 0);
}

.menuposs3 {
  margin-top: -100px;
  margin-left: -20px;
}

.listtop {
  background-color: none;
  width: 40px;
  height: 20px;
}

.menu li {
  width: 30vh;
  list-style: none;
  position: relative;
}

.menu a {
  color: #fff;
  text-decoration: none;
  display: block;
  text-align: center;
  height: 40px;
  line-height: 40px;
  transition: all .5s;
}

.menu a:hover {
  background: #808291;
}

.menu ul {
  background-color: rgb(20, 20, 20);
  position: absolute;
  left: 120%;
  top: 0%;
  display: none;
  padding: 0;
  backdrop-filter: blur(16px);
  border-radius: 10px;
}

.menu li:hover>ul {
  display: block;
}

.menu ul ul {
  display: none;
  top: 0;
  left: 100%;
}

.description {
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 400px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}

.homeButtTop1 {
  top: 30px
}

.homeButtTop2 {
  top: 60px
}

.homeButtTop3 {
  top: 90px
}

.homeButtTop4 {
  top: 90px
}

.home-title {
  width: 600px;
}

.header-phone {
  margin-top: -7px;
  width: 120px;
}

.for-width {
  width: 161px;
}

.display-div {
  display: flex;
  justify-content: center;
}

.ctlgImg {
  block-size: 200px;
}

.ctlgP {
  margin-left: 10px;
  margin-right: 10px;
}

.modal2 {
  background-color: rgb(85, 85, 85);
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.for-span {
  color: #fe6512;
  text-decoration: underline;
  text-decoration-color: #fe6512;
}

.about-us-width {
  margin-left: 100px;
  margin-right: 100px;
}

@media (min-width: 1000px) {
  .ctlgP {
    margin-left: 50px;
  }
}


@media (max-height: 700px) {
  .floating-button {
    margin-top: -10px;
  }
}

@media (max-width: 1400px) {
  .div-for-catalog-two {
    transform: scale(0.9);
  }
}

@media (max-width: 1100px) {
  .modal2 {
    max-width: auto;
  }
}

@media (max-width: 920px) {
  .div-for-catalog-two {
    transform: scale(1.2);
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media (min-width: 800px) {
  .onOff {
    display: none;
  }

  .onOffPhoneMenu {
    display: none;
  }

}

@media (max-width: 800px) {
  .butt {
    font-size: 15px;
  }

  .header-phone {
    transform: scale(0.8);
    margin-top: -14px;
  }

  .onOff {
    display: none;
  }

  .onOffPhoneMenu {
    display: none;
  }

  .about-us-width {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (max-width: 650px) {
  .homeButt {
    width: 450px;
  }

  .homeButtTop2 {
    top: 80px
  }

  .homeButtTop3 {
    top: 130px
  }

  .homeButtTop4 {
    top: 130px
  }

  .home-title {
    width: 500px;
  }

  .onOff {
    display: none;
  }

  .onOffPhoneMenu {
    display: none;
  }

  .ctlgImg {
    block-size: 150px;
  }

}

@media (max-width: 580px) {
  .butt {
    margin-right: 25px;
  }

  .onOff {
    display: none;
  }

  .onOffPhoneMenu {
    display: none;
  }

  .ctlgImg {
    display: none;
  }

  .ctlgP {
    margin-right: 10px;
    margin-left: 10px;
  }

  .div-for-catalog-two {
    transform: scale(1);
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 550px) {

  .labelFont {
    font-size: 15px;
  }

}

@media (max-width: 510px) {
  .homeButt {
    width: 350px;
    font-size: 20px;
  }

  .homeButtTop1 {
    top: 40px
  }

  .homeButtTop2 {
    top: 70px
  }

  .homeButtTop3 {
    top: 100px
  }

  .homeButtTop4 {
    top: 100px;
    font-size: 20px;
  }

  .homeButtTop4:hover {
    font-size: 25px;
  }

  .home-title {
    width: 400px;
  }

  .display-div {
    display: none;
  }

  .onOff {
    display: block;
  }

  .butt2:hover {
    font-size: 20px;
    color: rgb(223, 223, 0);
  }

  .onOffPhoneMenu {
    display: none;
  }

  .about-us-width {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .div-for-catalog-two {
    transform: scale(0.9);
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 450px) {

  .labelFont {
    font-size: 15px;
  }


  .lbl {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .homeButt {
    width: 300px;
    font-size: 18px;
  }

  .homeButtTop1 {
    top: 20px
  }

  .homeButtTop2 {
    top: 40px
  }

  .homeButtTop3 {
    top: 60px
  }

  .homeButtTop4 {
    top: 40px;
    font-size: 15px;
  }

  .homeButtTop4:hover {
    top: 40px;
    font-size: 20px;
  }

  .home-title {
    width: 350px;
  }

  .title-1 {
    font-size: 20px;
  }

  .title-2 {
    font-size: 15px;
  }

  .onOff {
    display: block;
  }

  .onOffPhoneMenu {
    display: none;
  }

  .ctlgP {
    font-size: 15px;
  }

  .div-for-catalog-two {
    width: 400px;
  }
}

@media (max-width: 350px) {
  .header-phone {
    display: none;
  }

  .menuposs3 {
    margin-top: -40px;
  }

  .onOffPhoneMenu {
    display: block;
  }

  .butt2 {
    margin-left: 50px;
  }

  .homeButt {
    width: 260px;
    font-size: 18px;
  }

  .accessories:hover {
    font-size: 18px;
  }

  .homeButtTop2 {
    top: 50px
  }

  .homeButtTop3 {
    top: 70px
  }

  .div-for-catalog-two {
    transform: scale(0.8);
    margin-top: 0px;
    margin-bottom: 0px;
  }

}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap')